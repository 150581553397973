.app {
  height: 100vh;
  width: 82vw;
  position: relative;
  overflow-y: scroll;
}

.page-container {
    height: 100vh;
    padding-bottom: 4vh;
    overflow-y: scroll;
    padding-right: 4vw;
}

.page-header {
    position: sticky;
    top: 0;
    background-color: white;
    padding-top: 1em;
    padding-bottom: 1em;
    z-index: 1;
}


.page-header.form {
    
}


.data-table {
}

.nav-link {
  background-color: wheat;
}

.nav-active {
  background-color: red;
}